import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';
import Layout from '../components/layout';
import moment from 'moment';
import _get from 'lodash/get';
import 'bootstrap/dist/css/bootstrap.min.css';

import { isAdmin } from '../utils';

class Log extends Component {
  constructor(props) {
    super(props);
    this.limit =
      window.location.search && parseInt(window.location.search.slice(1))
        ? parseInt(window.location.search.slice(1))
        : undefined;
    this.props.firebase.base.listenToCollection('logs', {
      context: this,
      state: 'logs',
      then() {
        this.props.trainings.getLogs(this.limit).then(logs => {
          if (this.mount) this.setState({ logs });
        });
      }
    });
  }

  state = {
    logs: []
  };

  componentDidMount() {
    this.mount = true;
    if (this.props.trainings) {
      this.props.trainings.getLogs(this.limit).then(logs => this.setState({ logs }));
      this.props.trainings.getTotalMoney().then(users => {
        let money = 0;
        users.forEach(user => {
          if (parseInt(user.money)) money += parseInt(user.money);
        });
        this.setState({ money });
      });
    }
  }
  render() {
    const user = { id: _get(this.props, 'firebase.auth.currentUser.uid') };
    if (!isAdmin(user)) {
      return null;
    }
    return (
      <div className="container" style={{ backgroundColor: 'white' }}>
        <h1>Журнал оплат:</h1>
        <h3>всего денег на рахунках пользователей: {this.state.money}</h3>
        <table className="table" style={{ color: 'black' }}>
          <thead>
            <tr>
              <th scope="col">Дата</th>
              <th scope="col">Запись</th>
              <th scope="col">Доход</th>
            </tr>
          </thead>
          <tbody>
            {this.state.logs.map(({ date, msg, income }) => (
              <tr key={date}>
                <th>{moment(date).format('HH:mm  DD MMM')}</th>
                <th>{msg}</th>
                <th>{income}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const LandingPageWithFirebase = withFirebase(withTrainings(Log));

export default () => (
  <Layout>
    <LandingPageWithFirebase />
  </Layout>
);
